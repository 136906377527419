export default {

  'Approved': {
    color: 'success',
    status: 'approved',
  },
  'In progress': {
    color: 'orange',
    status: 'pending',
  },
  'Waiting approval': {
    color: 'primary',
    status: 'final',
  },
  default: {
    color: 'orange',
    status: 'pending',
  },
};
